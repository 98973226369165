import React, { useEffect } from "react";
import { ReactTyped } from "react-typed";
import { HashLink as Link } from "react-router-hash-link";
import { Resize } from "@cloudinary/url-gen/actions/resize";
import { AdvancedImage } from "@cloudinary/react";
import { cld } from "../config/cloudinary";
import SEOHead from "../components/SEOHead";
import "../css/landingpage.css";
import "../css/navbar.css";
import "../css/web.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { normal } from "@cloudinary/url-gen/qualifiers/textDecoration";
export default function WebScraping() {
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <SEOHead
        title="Web Scraping"
        description="Web scraping. We lay out 8 main uses for web scraping as well as many other uses for your business. Whether that is data collection, competive analysis, market research, lead generation, content aggregation, automation, monitoring and alerts or whatever problem you need solved."
        name="KCB Software"
        type="service"
        link="https://kcbsoftware.com/services/web-scraping"
      />
      <div className="tabletContainer ">
        <AdvancedImage
          cldImg={cld
            .image("web-scraping_udxf2a")
            .resize(Resize.scale().width(1500))}
          className="backgroundImageWWA"
          alt="web, web scraping, Data Collection, Monitoring and Alerts, Automation, Data Integration, Competive analysis, software web, building web scraping, Lead Generation, Market Research"
        />
        <h1>
          <ReactTyped
            strings={["Web Scraping"]}
            typeSpeed={80}
            className="tablet-title center"
          ></ReactTyped>
        </h1>
      </div>
      <div className="line-navbar"></div>
      <div className="margin " style={{ backgroundColor: "#01171D" }}>
        <div className="row space-around bottom-ws">
          <div data-aos="fade-right" className="column">
            <h2 className="ws-subtitle">What is Web Scraping?</h2>
            <div className="ws-des">
              Web scraping is the process of automatically extracting
              information from the internet. It involves using scripts to
              collect data from anywhere on the internet, like text, images,
              data, or links, and storing it in a structured format. This is
              often done to gather large amounts of data quickly.
              <br />
              <br />
              You can use web scraping for many parts of a business, whether
              that’s finding clients in a niche, gathering information like
              news, getting information on the competition, gathering info for
              email lists, stock information, real estate listings , job
              listings, sport statistics, or any research data, from multiple
              different sources in real time.
            </div>
          </div>
          <AdvancedImage
            data-aos="fade-left"
            cldImg={cld
              .image("Web-Scraping-png.png_vcnnin")
              .resize(Resize.scale().width(500))}
            alt="web scraping, Data Collection, Monitoring and Alerts, Automation, Data Integration, Competive analysis, software web, building web scraping, Lead Generation, Market Research"
            className="ws-web-of-data"
          />
        </div>
      </div>
      <div className="line-navbar"></div>
      <div
        className="margin"
        style={{ backgroundColor: "#01171D", paddingLeft: "10%" }}
      >
        <h2 className="ws-subtitle" data-aos="fade-right">
          What are the 8 main uses for Web Scraping?
        </h2>
        <img
          data-aos="fade-up"
          className="ws-8Web"
          alt="web scraping, Data Collection, Monitoring and Alerts, Automation, Data Integration, Competive analysis, software web, building web scraping, Lead Generation, Market Research"
          src="https://res.cloudinary.com/kcb-software-design/image/upload/v1734666472/8-main-uses-for-web-scraping_grd9ym.webp"
        />
        <h3 data-aos="fade-right" className="ws-h3">
          Data Collection
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Web scraping is a process of collecting information from websites
          which would take a lot of time and effort to collect by hand. For
          instance, if you are interested in product prices, gathering
          information for email lists or marketing compaigns, potential client
          information, reviews or news articles then scraping can help you get
          this information automatically and in a structured manner.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Competive analysis
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Web scraping is also utilized in monitoring the competitors in order
          to know their movements and strategies. Thus, companies can get
          information on the competitors’ product prices, characteristics of
          offers, promotions, and even customers’ attitude to the company. This
          information can be further used to modify the strategies, identify the
          loopholes in the market or to enhance the market position as compared
          to the competitors.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Market Research
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Market researchers scrape data to understand trends, consumer behavior
          and preferences. They gather information from e-commerce sites,
          forums, social networks and so on to build up a overall picture. This
          assists the businesses to know the demand of the product, the trends
          in the market as well as the future changes that may occur in the
          market.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Lead Generation
        </h3>
        <div data-aos="fade-left" className="ws-des">
          For the sales team, web scraping can help in creating leads since it
          can extract contact information such as emails, phone numbers and
          social media contacts from business directories, industry websites,
          forums or social media pages and more. This in turn makes it easier to
          compile a list of leads that can be contacted for further marketing or
          sales endeavors.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Data Integration
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Most of the businesses require that they collect information from
          several platforms in order to generate one database. Web scraping
          enables businesses to scrape data from various websites and then use
          the data in their internal systems, databases, or applications. This
          is especially useful for gathering data from several e-commerce sites
          or market databases.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Content Aggregation
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Aggregation of content is the process of gathering information from
          different sites and presenting it on one website. Web scraping is
          commonly applied by the news aggregators, travel websites or job
          portals to collect articles, listings or posts from different sources
          and display them on one place.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Automation
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Web scraping is one of the most effective ways of performing routine
          tasks. For example, while it is possible to periodically visit a
          website to check for changes in product prices or inventory, it is
          easier and faster to create a web scraping bot to do it automatically.
          This means that time is saved and updates are made at the right time
          without the need of a human intervention.
        </div>
        <h3 data-aos="fade-right" className="ws-h3">
          Monitoring and Alerts
        </h3>
        <div data-aos="fade-left" className="ws-des">
          Web scraping can be configured in a way that it is able to watch
          certain websites for modifications, such as price cuts, product
          availability, or even new releases. When certain criteria are
          fulfilled, scraping tools can send out an alert. This is especially
          useful for the e-commerce companies to keep an eye on the competitor
          prices or the investors to track the financial information.
        </div>
      </div>
      <div className="line-navbar"></div>
      <div
        className="margin ws-end-cont"
        style={{ backgroundColor: "#01171D" }}
      >
        <div className="row space-around bottom-ws">
          <div data-aos="fade-up" className="ws-end">
            What ever your web scraping needs are, we have you covered. We can
            build you a specific web scraping algorthim that will fit your
            business and your needs uniquely. Your business is unique and so
            should your growth strategies.{" "}
            <Link to="/contact" onClick={scrollToTop} className="yellow-text">
              Contact
            </Link>{" "}
            us to learn more about how you can get you business to the next
            level!
          </div>
          <div>
            <img
              data-aos="fade-left"
              className="web-dev-graphic float rocketman ws-rocket"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719797536/KCB/Cute_Astronaut_Riding_Rocket_With_Peace_Hand_Converted_rxqzoa.png"
              alt="rocketman, web scraping, data, kcb software, business, Data Collection, Monitoring and Alerts, Automation, Data Integration, Competive analysis, software web, building web scraping, Lead Generation, Market Research "
            />
          </div>
        </div>
      </div>
      <div className="yellow margin">
        <ReactTyped
          strings={["Want to know more?"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center", color: "black" }}
        ></ReactTyped>
        <div className="b1" style={{ textAlign: "left", color: "#0f2a23" }}>
          Contact us via our contact form to learn more about what web
          development solution is best for you and your business.
        </div>
        <br />
        <br />
        <br />
        <Link
          to="/contact"
          className="button-primary"
          style={{ textDecoration: "none" }}
          onClick={scrollToTop}
        >
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}
