import React, { useState, useEffect, useContext } from "react";
import { ReactTyped } from "react-typed";
import { HashLink as Link } from "react-router-hash-link";
import "../css/landingpage.css";
import "../css/pages.css";
import "../css/web.css";
import SEOHead from "../components/SEOHead";

export default function Web() {
  const [anchorTarget, setAnchorTarget] = useState(null);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <SEOHead
        title="Web Development"
        description="Website development and design - wordpress, custom code and web apps. Whatever you have envisioned we can create of fix it, with our highly experienced team. E-commerce, web app, portfolio, or a complex system on the web, we have you covered with our highly experienced software development team. "
        name="KCB Software"
        type="service"
        link="https://kcbsoftware.com/services/webdev"
      />
      <div className="tabletContainer ">
        <img
          className="backgroundImageWWA"
          src="https://res.cloudinary.com/kcb-software-design/image/upload/v1713929760/woman-leisure-handmade-using-laptop-2023-11-27-04-59-55-utc_dvmcjs.png"
        />
        <ReactTyped
          strings={["Web Development"]}
          typeSpeed={80}
          className="tablet-title"
        ></ReactTyped>
      </div>
      <div className="line-navbar"></div>
      <div className="block2" style={{ backgroundColor: "#e5e550" }}>
        <div
          className="b1"
          style={{ color: "#0f2a23", textAlign: "left", paddingLeft: "30px" }}
        >
          <b>
            Having a well-made website / web app is a fundamental component of
            modern business strategy,
          </b>{" "}
          offering a multitude of benefits that can positively impact your
          brand, customer engagement, and bottom line. Firstly, a professionally
          designed website / web app serves as your digital storefront, often
          forming the first impression potential customers have of your
          business. A visually appealing and user-friendly website conveys
          professionalism, credibility, and trustworthiness, helping to instill
          confidence in your brand and differentiate you from competitors.{" "}
          <br />
          <br />A website / web app created with excellence enhances
          accessibility and convenience for your customers, providing them with
          a centralized platform to learn about your products or services, make
          purchases, perform tasks and interact with your brand at their own
          convenience. This 24/7 accessibility expands your reach beyond
          geographic boundaries, enabling you to attract and engage with a
          global audience.
        </div>
        <img
          className="web-dev-graphic"
          src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719789510/KCB/Web_development_chdxyy.png"
          alt="web dev, web dev graphic, website development icon"
        ></img>
      </div>
      <div className="line-navbar"></div>
      <div className="block1 valuesBlock" style={{ paddingTop: "40px" }}>
        <ReactTyped
          strings={["Types of web development we offer"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center" }}
        ></ReactTyped>

        <div
          className="column"
          style={{ alignItems: "center", width: "100vw" }}
        >
          <div
            className="b1"
            style={{
              textAlign: "center",
              paddingLeft: "3vw",
              paddingRight: "3vw",
              alignSelf: "center",
              paddingTop: "20px",
            }}
          >
            Here at KCB we offer multiple different solutions for web
            development to cater to every businesses individual needs. Whether
            you need a landing page to showcase your business, an ecommerce
            platform or a full web app with custom capabilities to enhance our
            world, we got you covered. <br /> We offer different options based
            on budget, size, and needs. We recommend wordpress for simplier
            projects, landing pages, eccommerce, portfolios etc. Although we do
            offer custom coded websites. Custom coded websites as well and have
            many advantages over wordpress websites. When it comes to web apps
            and web platforms we specialize in multiple different coding
            frameworks and depending on how you want to scale your platform, we
            can recommend and build accordingly to ensure the best finished
            product.
          </div>
          <div className="sticky-container">
            <Link
              to="/services/webdev#wordpress"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              <img
                className="types-of-design"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719723639/KCB/wordpress-postit_ymconw.png"
                alt="wordpress, wordpress development, web design, web development"
              />
            </Link>
            <Link
              to="/services/webdev#customcode"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              <img
                className="types-of-design"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719723637/KCB/custom-code-sticky_ndfdxb.png"
                alt="custom code,  custom website, web design, web development"
              />
            </Link>
            <Link
              to="/services/webdev#webapps"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              <img
                className="types-of-design"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719723639/KCB/webappsticky_yohn9k.png"
                alt="web apps, web platforms, web design, web development, kcb software"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div id="wordpress" className="blue margin">
        <div class="row rowreverse" style={{ justifyContent: "space-around" }}>
          <img
            className="types-images"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719723639/KCB/wordpress-postit_ymconw.png"
            alt="wordpress, wordpress development, web design, web development"
          />
          <div className="b1" style={{ textAlign: "left" }}>
            We offer top of the line wordpress websites. We offer design
            services, straight to the development. We recommend wordpress for
            basic websites to e-commerce websites. Wordpress is a powerful tool
            and is a budget friendly option as it takes a shorter amount of time
            to make and set up. We encorpoate elementor into our wordpress
            websites for our clients. We recommend wordpress over other built in
            website makers, as wordpress is more customizable and has more
            features and plugins available. As well as it is built to easily add
            code into the website to add more customizable components to suit
            each businesses needs.
          </div>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div id="customcode" className="green margin">
        <div
          class="row columnreverse"
          style={{ justifyContent: "space-around" }}
        >
          <div className="b1" style={{ textAlign: "left" }}>
            Custom coded websites are an amazing option for businesses that
            value flexibility and security. With custom coded websites you get a
            unique platform, more secure than a website builder and much for
            flexibility on aspects of your website, as they are all custom coded
            by our team to specifically suit your vision. We recommend custom
            websites for businesses that are looking to scale to a web app in
            the future, need specific functionalities that are unique to their
            business that would benifit from being custom fit , and businesses
            that are handling very sensitive information.
          </div>
          <img
            className="types-images"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719723637/KCB/custom-code-sticky_ndfdxb.png"
            alt="custom code,  custom website, web design, web development"
          />
        </div>
      </div>
      <div className="line-navbar"></div>
      <div id="webapps" className="blue margin">
        <div class="row rowreverse" style={{ justifyContent: "space-around" }}>
          <img
            className="types-images"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1732605751/web_apps_stickynote_cj1zjf.png"
            alt="web apps, web platforms, web design, web development, kcb software"
          />
          <div className="b1" style={{ textAlign: "left" }}>
            A web application (web app) is an application program that is stored
            on a remote server and delivered over the internet through a browser
            interface. A custom application on the web that performs specific
            tasks and functionalities for their users. We definitley specialize
            in this area, with many web apps we have worked on on the market.
            Web apps are large applications, and are an effective way to display
            your services to a large audience. With custom tools, algorithms,
            and interfaces built into the design to optimize your product.
            Depending on the business model, they are often built along side
            mobile apps.
          </div>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div className="yellow margin">
        <ReactTyped
          strings={["Want to know more?"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center", color: "black" }}
        ></ReactTyped>
        <div className="b1" style={{ textAlign: "left", color: "#0f2a23" }}>
          Contact us via our contact form to learn more about what web
          development solution is best for you and your business.
        </div>
        <br />
        <br />
        <br />
        <Link
          onClick={scrollToTop}
          to="/contact"
          className="button-primary"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}
