import React, { useState } from "react";
import { SidebarData } from "../config/SidebarData";
import { FaWhatsapp } from "react-icons/fa";
import "../css/navbar.css";

import LazyLoad from "react-lazyload";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="line-navbar"></div>
      <div>
        <img
          className="logoFooter"
          src="https://res.cloudinary.com/kcb-software-design/image/upload/c_scale,w_200/v1732771120/KCB-LOGO-VERTICAL-WHITE_m08kni.webp"
          alt="kcb software & design, software Vancouver, software company, web design, app design, web development, website development, SEO, algorithms, business software, IT"
        />
        <div className="lsb1">
          Stay connected. Fill out our form for inquiries, quotes or to just say
          hi!
          <br />
          <br />
          WhatsApp:
          <br /> 1-831-699-6674
          <br />
          <a href="https://www.instagram.com/kcbsoftware/">
            <img
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714010471/instaiconwhite_y81ghq.png"
              alt="instagram icon white"
              class="instaIcon"
            />
          </a>
          <a
            style={{ paddingLeft: "30px" }}
            href="https://wa.me/18316996674?text=Hello, I am coming from KCB's website, I have a question."
          >
            <FaWhatsapp className="socialicons" />
          </a>
        </div>
      </div>
    </div>
  );
}
