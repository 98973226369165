import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import "../css/landingpage.css";
import "../css/navbar.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink as Link } from "react-router-hash-link";
import { ReactTyped } from "react-typed";
import Footer from "../components/Footer";
import SEOHead from "../components/SEOHead";
import { Resize } from "@cloudinary/url-gen/actions/resize";
import { AdvancedImage } from "@cloudinary/react";
import { cld } from "../config/cloudinary";

export default function LandingPage() {
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="landingpage-container">
      <SEOHead
        title="KCB Software"
        description="Software development company specializing in web and app development, automation, web scraping, general programming and SEO. Whatever your software development needs are we can make them a reality. "
        name="KCB Software"
        type="website"
        link="https://kcbsoftware.com/"
      />
      <div className="block block1">
        <LazyLoad>
          <img
            data-aos="fade-up"
            className="phone"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1732770772/kcb_software_development_iphone_mockup_uuts6h.webp"
            alt="coding, code, programmer, software, phone, phone png, kcb, kcb software, vancouver"
          />
        </LazyLoad>
        <div className="typedTitle">
          <ReactTyped
            strings={[
              "<span style='color: #e5e550'>Transforming</span> Ideas into <span style='color: #e5e550'>Intelligent</span> Solutions.. ",
            ]}
            typeSpeed={80}
          ></ReactTyped>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div className=" block2 lp-b2">
        <div data-aos="fade-right" className="introductionContainer">
          <div className="typedTitle2 title2">
            <ReactTyped strings={[" Intoducing.."]} typeSpeed={80}></ReactTyped>
          </div>
          <div className="introductionPara lpp2">
            KCB Software & Design: where complexity is mastery with the highest
            level of customer satisfaction. As a software development company
            focused in creating solutions to support companies in adapting to
            the digital era; our team of experts excels, in addressing intricate
            challenges through streamlined and user friendly software
            applications fueled by creativity and problem solving skills. We are
            skilled software developers with the latest and leading software
            technologies that allows us to make our customers dreams a reality,
            with our own products that we have developed for them. Because we
            are proven to succeed and focused on customer happiness, we go above
            and beyond to ensure every client experience with us is a success.
            At KCB Software & Design we don’t just solve problems — we reimagine
            possibilities
            <br />
            <br />
          </div>
          <Link
            onClick={scrollToTop}
            to="/whoweare"
            className="button-primary wwa-lp"
            style={{ textDecoration: "none" }}
          >
            {" "}
            About us
          </Link>
        </div>
        <LazyLoad>
          <img
            className="phoneblock2"
            data-aos="fade-up"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1713925263/01_Q3i_Phone_Mockup3_e7g5gr.png"
            alt="coding, code, programmer, software, phone, phone png, kcb, kcb software, software development vancouver"
          ></img>
        </LazyLoad>
      </div>
      <div className="line-navbar"></div>
      <div className="block3">
        <div className="typedTitle2 title3">
          <ReactTyped strings={["Specialties.."]} typeSpeed={80}></ReactTyped>
        </div>
        <div className="b3b1" data-aos="fade-in">
          We specialize in all things software, if you do not see your desired
          service in our list, please contact us. There is a high probabilty we
          can exceed your expectations.
        </div>
        <div className="specialtyContainer">
          {/* <div className="specImgContainer">
            <LazyLoad>
              <img
                className="specImage"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1713929756/gears-and-cogs-clock-2023-11-27-05-13-06-utc_p4vawt.jpg"
                alt=""
              />
            </LazyLoad>
            <div className="specImgText">Automation</div>
          </div> */}
          <div data-aos="flip-left">
            <Link onClick={scrollToTop} to="/services/webdev">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image(
                        "woman-leisure-handmade-using-laptop-2023-11-27-04-59-55-utc_dvmcjs"
                      )
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="web development, website development, making website, web apps, web development company"
                  />
                </LazyLoad>
                <div className="specImgText">Web Development</div>
              </div>
            </Link>
          </div>
          <div data-aos="flip-right">
            <Link onClick={scrollToTop} to="/services/seo">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image(
                        "computer-connection-internet-networking-digital-co-2023-11-27-05-09-42-utc_nosccs"
                      )
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="seo, search engine optimization, seo company, ranking on google, seo in my area"
                  />
                </LazyLoad>
                <div className="specImgText">SEO</div>
              </div>
            </Link>
          </div>
          <div data-aos="flip-left">
            <Link onClick={scrollToTop} to="/services/web-scraping">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image("8-main-uses-for-web-scraping_grd9ym")
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="web scraping, bots, automation, selenium, web testing, instagram bots, whatsapp bots web development"
                  />
                </LazyLoad>
                <div className="specImgText">Web Scraping</div>
              </div>
            </Link>
          </div>
          <div data-aos="flip-left">
            <Link onClick={scrollToTop} to="/services/appdev">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image(
                        "traveler-listening-to-music-on-a-smartphone-2023-11-27-04-58-40-utc_wllxjy"
                      )
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="application development, software development, app development, apps, iphone apps, android apps, react native apps"
                  />
                </LazyLoad>
                <div className="specImgText">App Development</div>
              </div>
            </Link>
          </div>
          <div data-aos="flip-left">
            <Link onClick={scrollToTop} to="/services/automation">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image(
                        "vintage-robot-tin-toy-on-yello-background-2023-11-27-05-05-22-utc_map0dz"
                      )
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="web scraping, bots, automation, selenium, web testing, instagram bots, whatsapp bots web development"
                  />
                </LazyLoad>
                <div className="specImgText">Automation</div>
              </div>
            </Link>
          </div>
          {/* <div data-aos="flip-left">
            <Link onClick={scrollToTop} to="/services/appdev">
              <div className="specImgContainer">
                <LazyLoad>
                  <AdvancedImage
                    cldImg={cld
                      .image(
                        "traveler-listening-to-music-on-a-smartphone-2023-11-27-04-58-40-utc_wllxjy"
                      )
                      .resize(Resize.scale().width(400))}
                    className="specImage"
                    alt="application development, software development, app development, apps, iphone apps, android apps, react native apps"
                  />
                </LazyLoad>
                <div className="specImgText">App Development</div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
      <div className="yellow margin">
        <ReactTyped
          strings={["Want to know more?"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center", color: "black" }}
        ></ReactTyped>
        <div className="b1" style={{ textAlign: "left", color: "#0f2a23" }}>
          Contact us via our contact form to learn more about what web
          development solution is best for you and your business.
        </div>
        <br />
        <br />
        <br />
        <Link
          onClick={scrollToTop}
          to="/contact"
          className="button-primary"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}
