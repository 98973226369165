import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import { app } from "../config/firebase.js";
import * as firebase from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { HashLink as Link } from "react-router-hash-link";
import { FaWhatsapp } from "react-icons/fa";
import "../css/landingpage.css";
import "../css/pages.css";
import "../css/web.css";
import SEOHead from "../components/SEOHead";

const firebaseFunctions = getFunctions(app, "us-central1");
export default function Contact() {
  const [ValidErr, setRaiseErr] = useState({});
  const [ValidSend, setSentMessage] = useState({});
  let NameInput = React.createRef();
  let EmailInput = React.createRef();
  let CompanyInput = React.createRef();
  let Comment = React.createRef();

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      const sendEmail = httpsCallable(firebaseFunctions, "sendKCBEmail", app);
      sendEmail({
        company: CompanyInput.current.value,
        name: NameInput.current.value,
        email: EmailInput.current.value,
        comment: Comment.current.value,
      })
        .then((result) => {
          console.log("email sending result:" + result);
          const ValidSend = {};
          ValidSend.RequiredFieldsEmpty =
            "Your Email is Sent! We will get Back to you within 24 hours";
          setRaiseErr({});
          setSentMessage(ValidSend);
        })
        .catch((error) => {
          console.log(error);
          console.log("frontend error");
        });
      CompanyInput.current.value = "";
      NameInput.current.value = "";
      EmailInput.current.value = "";
      Comment.current.value = "";
    }
  };
  const formValidation = () => {
    let isValid = true;
    const ValidErr = {};

    if (
      NameInput.current.value === "" ||
      EmailInput.current.value === "" ||
      Comment.current.value === ""
    ) {
      ValidErr.RequiredFieldsEmpty = "You Must Fill In All Required Fields";
      isValid = false;
      setRaiseErr(ValidErr);
    }

    return isValid;
  };

  return (
    <div className="margin lb-bg">
      <SEOHead
        title="Contact us"
        description="Contact page for KCB Software & Design. Fill our our form and let us know how we can help you develop the software of your dreams from our business based in Vancouver BC."
        name="KCB Software"
        type="contact"
        link="https://kcbsoftware.com/contact"
      />
      <div className="contact-con-1">
        <div className="con-title">Contact Us</div>
        <div className="con-sub">
          Message us via our form with any questions or inquiries for us!
          <br />
          <br />
          Please include what service of ours you are interested in so we can
          help you better.
        </div>
        <a href="https://www.instagram.com/kcbsoftware/">
          <img
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714010471/instaiconwhite_y81ghq.png"
            alt="instagram icon white"
            className="socialicons"
          />
        </a>
        <a
          style={{ paddingLeft: "30px" }}
          href="https://wa.me/18316996674?text=Hello, I am coming from KCB's website, I have a question."
        >
          <FaWhatsapp className="socialicons" />
        </a>
        <br />
        <div style={{ color: "white", paddingTop: "30px" }}>
          {" "}
          1-831-699-6674
        </div>
      </div>
      <div id="Contact" className="email-container">
        <form className="form" onSubmit={onSubmit}>
          <div className="column">
            <label className="questions"> Name*</label>
            <input className="inputs-con" ref={NameInput}></input>
          </div>
          <br />
          <div className="column">
            <label className="questions">Email*</label>
            <input className="inputs-con" ref={EmailInput}></input>
          </div>

          <br />
          <div className="column">
            <label className="questions">Company</label>
            <input className="inputs-con" ref={CompanyInput}></input>
          </div>
          <br />
          <div className="column">
            <label className="questions">Tell Us About Your Needs*</label>
            <textarea className="inputs-con" ref={Comment}></textarea>
          </div>
          <br />
          {Object.keys(ValidErr).map((key) => {
            return <div style={{ color: "red" }}>{ValidErr[key]}</div>;
          })}
          {Object.keys(ValidSend).map((key) => {
            return (
              <div style={{ color: "green", paddingBottom: "25px" }}>
                {ValidSend[key]}
              </div>
            );
          })}
          <button className="submitButton" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
